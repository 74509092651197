import Header from "./components/Header";
import Footer from "./components/Footer";
import makeStyles from "@mui/styles/makeStyles";
import { Box, ButtonBase, Paper } from "@mui/material";
import clsx from "clsx";
import { AppRoutes } from "./routes";
import "./i18n";
import DocumentMeta from "react-document-meta";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    padding: "100px 0 0 0",
    [theme.breakpoints.down("sm")]: {
      padding: "50px 0 0 0",
    },
  },
}));

function App() {
  const classes = useStyles();

  const meta = {
    title: "Bharat Mother of Democracy",
    description: "Bharat Mother of Democracy",
    canonical: "https://bharatmotherofdemocracy.ignca.gov.in/",
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "Mother,Bharat the mother of democracy,democracy,Democracy, Bharat, bharat",
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <div className={clsx("App", classes.root)}>
        <Header />
        <AppRoutes />
        <Footer />
      </div>
    </DocumentMeta>
  );
}

export default App;
