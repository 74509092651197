import React from "react";
import { createRoot } from 'react-dom/client';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import translateEnglish from '../src/locales/en/translation.json'
import translateHindi from '../src/locales/hi/translation.json'
import translateTamil from '../src/locales/ta/translation.json'
import translateUrdu from '../src/locales/ur/translation.json'
import translatePanjabi from '../src/locales/pa/translation.json'
import translateGujarati from '../src/locales/gu/translation.json'
import translateMarathi from '../src/locales/mr/translation.json'
import translateBangla from '../src/locales/bn/translation.json'
import translateOdia from '../src/locales/or/translation.json'
import translateAssamese from '../src/locales/asm/translation.json'
import translateKannada from '../src/locales/kn/translation.json'
import translateTelugu from '../src/locales/te/translation.json'
import translateMalayalam from '../src/locales/ml/translation.json'



i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
        en: {
            translation: translateEnglish
          },
          hi: {
            translation: translateHindi
          },
          ta: {
            translation: translateTamil
          },
          asm: {
            translation: translateAssamese
          },
          bn: {
            translation: translateBangla
          },
          gu: {
            translation: translateGujarati
          },
          kn: {
            translation: translateKannada
          },
          ml: {
            translation: translateMalayalam
          },
          mr: {
            translation: translateMarathi
          },
          or: {
            translation: translateOdia
          },
          pa: {
            translation: translatePanjabi
          },
          te: {
            translation: translateTelugu
          },
          ur: {
            translation: translateUrdu
          }
    },
    lng: localStorage.getItem("ModLanguage")?localStorage.getItem("ModLanguage"):"en", // if you're using a language detector, do not define the lng option
    fallbackLng: ["en","hi"],
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });