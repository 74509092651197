// const BASE_URL = process.env.PUBLIC_URL
// const BASE_URL = 'https://mod.sensez9.tech/mother-of-democracy/web/mod' //sensez
// const BASE_URL = 'http://10.194.81.230/mod/mother-of-democracy/web/mod' //NIC
const BASE_URL =
  "https://bharatmotherofdemocracy.ignca.gov.in/mother-of-democracy/web/mod"; //AWS
// http://10.194.81.230/mod/mother-of-democracy/web/
// const BASE_URL='http://164.100.211.139/mother-of-democracy/web/mod'
export const HOME_URL = `${BASE_URL}/api/v1/choose_languages`;
export const CONTENT_PARTICULAR_URL = `${BASE_URL}/api/v1/rich_legacy_of_democracy_detail`;
export const LEGACY_OF_DEMOCRACY_URL = `${BASE_URL}/api/v1/rich_legacy_of_democracy`;
export const ANCIENT_ROOTS_URL = `${BASE_URL}/api/v1/ancient-roots`;
