import * as React from "react";
import { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import makeStyles from "@mui/styles/makeStyles";
import Langmodal from "./Modal2/index.js";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../store/languageReducer";
import { HOME_URL } from "../../utils/applicationUrl";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "20px",
    background: "white",
    color: "#4e342ce8",
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: "11px",
    boxShadow: "0px 3px 3px #00000017",
    marginRight: "20px",
    transition: "all 0.5s",
    lineHeight: 1,
    "&:hover": {
      background: "rgba(0,0,0,0.1)",
    },
  },
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 20,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function CustomizedMenus() {
  const classes = useStyles();
  const [viewModal, setViewModal] = useState(false);
  const [langdata, setLangdata] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const location = useLocation();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (item) => {
    // console.log(item,'selectedlang');
    if (item.language_code != "en" ) {
      console.log("nothing");
      setViewModal(true);
      setLangdata(item)
    } else {
      localStorage.setItem("ModLanguage", item.language_code);
      setAnchorEl(null);
      let url = window.location.href.split("/");
      let newUrl = window.location.href;

      console.log("newUrl select", newUrl);
      if (url.length > 5) {
        url[url.length - 1] = item.language_code;
        newUrl = url.join("/");
      }
      console.log(window.location.href, newUrl, url.length);
      window.location.replace(newUrl);
    }
    // dispatch(setLanguage(item))
  };

  const language = useSelector((state) => state.language);
  const dispatch = useDispatch();

  const [data, setData] = React.useState();
  const [lang, setLang] = React.useState(localStorage.getItem("ModLanguage"));

  const urlFirstPart = window.location.href.split("?");
  const urlLang =
    urlFirstPart[0].split("/")[urlFirstPart[0].split("/").length - 1];
  const urlLang2 =
    urlFirstPart[0].split("/")[urlFirstPart[0].split("/").length - 2];
  console.log(urlLang, urlLang2);
  const something = localStorage.getItem("ModLanguage");
  if (!something) {
    localStorage.setItem("ModLanguage", "en");
  }
  React.useEffect(() => {
    axios({
      url: HOME_URL,
      method: "get",
    })
      .then((res) => {
        // console.log("click data response message", res.data?.message);
        setData(res.data?.data);
        let index = 1;
        if (urlLang) {
          index = res.data?.data?.findIndex((i) => {
            return urlLang === i.language_code;
          });
          if (index !== -1) {
            if (urlLang === localStorage.getItem("ModLanguage")) {
              return;
            } else {
              localStorage.setItem("ModLanguage", urlLang);
              window.location.reload();
            }
          }
        } else if (urlLang2) {
          index = res.data?.data?.findIndex((i) => {
            return urlLang2 === i.language_code;
          });
          if (index !== -1) {
            if (urlLang2 === localStorage.getItem("ModLanguage")) {
              return;
            } else {
              localStorage.setItem("ModLanguage", urlLang2);
              window.location.reload();
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [localStorage.getItem("ModLanguage")]);

  React.useEffect(() => {
    setLang(localStorage.getItem("ModLanguage"));
    // dispatch(setLanguage(item))
  }, [location]);

  return (
    <>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        className={classes.button}
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {lang
          ? data?.find((i) => i.language_code === lang)?.translated_language
          : "SELECT LANGUAGE"}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        {data?.map((item) => (
          <MenuItem
            sx={{
              fontFamily: "Poppins",
              fontSize: "16px !important",
              // display:
              //   item.language_code != "en" &&
              //   item.language_code != "hi" &&
              //   "none",
            }}
            onClick={() => handleClose(item)}
            disableRipple
          >
            {item.translated_language}
          </MenuItem>
        ))}
      </StyledMenu>
      <Langmodal viewModal={viewModal} language={langdata} setViewModal={setViewModal} />
    </>
  );
}
