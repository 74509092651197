import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
// import ReactPlayer from "react-player";
import makeStyles from "@mui/styles/makeStyles";
// import left from "../leftMoustache.svg";
// import right from "../rightMoustache.svg";
import CustomTooltip from "../../../components/Tooltip";
import "./style.scss";
import { ButtonBase } from "@mui/material";
// import VideoJS from "../Video";
// import VideoPlayer from "./index2";
// import ReactHlsPlayer from "react-hls-player";
// import VideoJsPlayer from "../Video/videoNew";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: "none",
    maxWidth: "none",
    overflow: "clip",
    borderRadius: "20px !important",
    width: "550px !important",
    height: "250px !important",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  contentTitle: {
    fontSize: "40px",
    fontFamily: "Kaushan Script",
    color: "#765B26",
    position: "relative",
    width: "fit-content",
    marginLeft: "80px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  leftMoustache: {
    width: "60px",
    position: "absolute",
    left: "-80px",
    bottom: 10,
    [theme.breakpoints.down("sm")]: {
      width: "30px",
      left: "-40px",
    },
  },
  rightMoustache: {
    width: "60px",
    position: "absolute",
    right: "-80px",
    bottom: 10,
    [theme.breakpoints.down("sm")]: {
      width: "30px",
      right: "-40px",
    },
  },
  closeButton: {
    // background: '#765b26',
    // padding: '5px 25px',
    // fontFamily: 'Roboto',
    // fontSize: '20px',
    // color: 'white',
    // borderRadius: '25px'
    background: "#765B26",
    padding: "5px 15px",
    borderRadius: "25px",
    fontFamily: "Roboto",
    fontSize: "17px",
    // marginRight: '20px',
    color: "white",
    [theme.breakpoints.down("md")]: {
      marginTop: "20px",
    },
  },
  buttons: {
    background: "#765B26",
    padding: "0 15px",
    borderRadius: "25px",
    fontFamily: "Roboto",
    // marginRight: '20px',
    fontSize: "20px",
    color: "white",
    [theme.breakpoints.down("md")]: {
      marginTop: "20px",
    },
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs(props) {
  const { viewModal, setViewModal, language } = props;
  // console.log(props.language,'languageslect');
  const { t } = useTranslation();

  const classes = useStyles();
  console.log(props);

  const handleClose = () => {
    setViewModal(false);
  };
  const playerRef = React.useRef();

  function playVideo() {
    playerRef.current.play();
  }

  function pauseVideo() {
    playerRef.current.pause();
  }

  function toggleControls() {
    playerRef.current.controls = !playerRef.current.controls;
  }

  const [watcher, setWatcher] = useState(0); // for Testing
  const [source, setSource] = useState(null);

  const videoSrc = {
    autoplay: true,
    controls: true,
    responsive: true,
  };

  const handleAction = (actionName) => {
    // if (actionName === "next") {
    //   if (watcher === playList.length - 1) {
    //     setWatcher(0);
    //   } else {
    //     setWatcher((prev) => prev + 1);
    //   }
    // } else if (actionName === "before") {
    //   if (watcher === 0) {
    //     setWatcher(playList.length - 1);
    //   } else {
    //     setWatcher((prev) => prev - 1);
    //   }
    // }
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={viewModal}
      language={language}
      classes={{
        paper: classes.root,
      }}
      className="modal2maindiv"
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Typography
          className={classes.contentTitle}
          style={{ margin: "auto", fontFamily: "Poppins", color: "#4e342c" }}
        >
          {language
            ? require(`../../../locales/${language?.language_code}/translation.json`)
                .COMING_SOON
            : ""}
          {/* {props?.modalData?.title?.replace("<br>", "")} */}
          {/* {t("COMING_SOON")} */}
          {/* {"COMING SOON"} */}
          {/* <img src={left} className={classes.leftMoustache} />
          <img src={right} className={classes.rightMoustache} /> */}
        </Typography>
      </BootstrapDialogTitle>

      <DialogContent
        dividers
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          fontSize: "20px",
          fontFamily: "Poppins",
          color: "#4e342c",
        }}
        className="dislogcontentmodal2"
      >
        {/* {
        props.modalData?.url?
        <VideoJsPlayer
          source={props.modalData?.url}
          options={videoSrc}
          handleActionNext={() => handleAction("next")}
          handleActionBefore={() => handleAction("before")}
          teaserStartTime={1}
          teaserDuration={20}
        />
        :""} */}
        {/* {t("LANGUAGE_UNAVAILABLE")} */}
        {/* {"This language is currently not available"} */}
        {language
          ? require(`../../../locales/${language?.language_code}/translation.json`)
              .LANGUAGE_UNAVAILABLE
          : ""}
      </DialogContent>
      <DialogActions>
        {/* <CustomTooltip title={t("CLICK_HERE_TO_CLOSE")}> */}
        <CustomTooltip
          title={
            language
              ? require(`../../../locales/${language?.language_code}/translation.json`)
                  .CLICK_HERE_TO_CLOSE
              : ""
          }
        >
          <ButtonBase
            className={classes.closeButton}
            onClick={() => {
              setViewModal(false);
            }}
            style={{
              background: "#4e342c",
            }}
          >
            <Typography style={{ fontFamily: "Poppins" }} variant="thinsmall">
              {/* {"CLOSE"} */}
              {language
                ? require(`../../../locales/${language?.language_code}/translation.json`)
                    .CLOSE
                : ""}
            </Typography>
          </ButtonBase>
        </CustomTooltip>
      </DialogActions>
    </BootstrapDialog>
  );
}
