import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    language: {
        langauge_name: '', 
        language_code: ''
    }
}

export const languageReducer = createSlice({
    name: 'language', 
    initialState, 
    reducers: {
        setLanguage: (state, action) => {
            state.language = action.payload
        },
    }
})

export const {setLanguage} = languageReducer.actions
export default languageReducer.reducer